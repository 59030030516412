import React from 'react'
import { connect } from 'react-redux';
import Layout from '../components/layout'
import SEO from '../components/seo'

function Imprint({ target, translations }) {
    return (
        <Layout>
            <SEO title={translations.legal_notice_title} description={translations.legal_notice_meta_description} />
            <div className={target === "b2c" ? 'legal-notice-cust' : ''}>
                <div className="subpage plain-text-content">
                    <div className="lightholder"></div>
                    <section>
                        <div className="inner-wrapper mb-2">
                            <h1>
                                Impressum
                            </h1>
                            <p className="headline yellow mt-3 mb">
                                Angaben gemäß § 5 TMG
                            </p>
                            BODYSHAKE® GmbH<br />
                            Brauereistr. 45<br />
                            08064 Zwickau <br /><br />
                            Registergericht: Amtsgericht Chemnitz <br />
                            Registernummer: HRB 33348 <br /><br />
                            <p className="headline yellow mt-3 mb">
                                Vertreten durch
                            </p>
                            die Geschäftsführer Steve Hachenberger und Daniel Ratzow
                            <p className="headline yellow mt-3 mb">
                                Kontakt
                            </p>
                            Telefon: <a href="tel:+4937569245150" target="_blank" rel="noreferrer noopener">+4937569245150</a> <br />
                            E-Mail: <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#105;&#110;&#102;&#111;&#64;&#98;&#111;&#100;&#121;&#115;&#104;&#97;&#107;&#101;&#46;&#99;&#111;&#109;">&#105;&#110;&#102;&#111;&#64;&#98;&#111;&#100;&#121;&#115;&#104;&#97;&#107;&#101;&#46;&#99;&#111;&#109;</a>
                            <p className="headline yellow mt-3 mb">
                                Umsatzsteuer
                            </p>
                            Umsatzsteuer-Identifikationsnummer gemass § 27 a Umsatzsteuergesetz: <br />
                            DE815878267
                            <p className="headline yellow mt-3 mb">
                                Gestaltung und Realisierung
                            </p>
                            Ö-Konzept GmbH &amp; Co. KG <br />
                            Audistr. 3 <br />
                            08058 Zwickau
                            <p className="headline yellow mt-3 mb">
                                Online Streitbeilegung
                            </p>
                            <span>Wir beteiligen uns nicht an einem System zur Alternativen Streitschlichtung im Sinne der EU-VO Nr. 524/2013, gerne können Sie sich aber unseren Kundenservice wenden. Sie erreichen uns zum Beispiel in allen Angelegenheiten unter <a href="mailto:info@bodyshake.com">info@bodyshake.com</a>.<br /> Den gleichwohl vorgeschriebenen Link zur Plattform der EU zur Online-Streitschlichtung (Verfügbarkeit liegt in der Verantwortung der EU) erreichen Sie <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noreferrer">hier</a>.</span>
                            <p className="headline yellow mt-3 mb">
                                Soziale Netzwerke
                            </p>
                            Dieses Impressum gilt auch für folgende Seiten bzw. Accounts in sozialen Netzwerken:
                            <ul>
                                <li>Facebook-Seite »Bodyshake« (<a href="https://www.facebook.com/bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.facebook.com/bodyshakeofficial</a>)</li>
                                <li>Twitter-Account »Bodyshake« (<a href="https://www.twitter.com/bodyshake_com" target="_blank" rel="noreferrer noopener">https://www.twitter.com/bodyshake_com</a>)</li>
                                <li>Instagram-Account »bodyshakeofficial« (<a href="https://www.instagram.com/bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.instagram.com/bodyshakeofficial</a>)</li>
                                <li>YouTube-Kanal »Bodyshake« (<a href="https://www.youtube.com/@bodyshakeoffical" target="_blank" rel="noreferrer noopener">https://www.youtube.com/@bodyshakeoffical</a>)</li>
                                <li>LinkedIn-Seite »BODYSHAKE« (<a href="https://www.linkedin.com/company/bodyshake" target="_blank" rel="noreferrer noopener">https://www.linkedin.com/company/bodyshake</a>)</li>
                                <li>TikTok-Account »bodyshakeofficial« (<a href="https://www.tiktok.com/@bodyshakeofficial" target="_blank" rel="noreferrer noopener">https://www.tiktok.com/@bodyshakeofficial</a>)</li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </Layout>
    )
}

const mapStateToProps = state => ({
    target: state.preferences.target,
    translations: state.preferences.translations,
})

export default connect(mapStateToProps)(Imprint)